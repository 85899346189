import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./routes/homepagev2";
import Questionaire from "./routes/questionaires/questions";
import Suggestion from "./routes/questionaires/suggestions";
import AboutPage from "./routes/aboutpage";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const THEME = createTheme({
    palette: {
        primary: {
            light: "#0eed76",
            main: "#0BCB64",
            dark: "#099449",
            contrastText: "#fff",
        },
        secondary: {
            light: "#008780",
            main: "#004743",
            dark: "#00302e",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: `Spartan,'Helvetica Neue',Arial,Helvetica,sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
});

ReactDOM.render(
    <ThemeProvider theme={THEME}>
        <BrowserRouter>
            {/* <React.StrictMode> */}
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="" element={<Homepage />} />
                    <Route path="q" element={<Questionaire />} />
                    <Route path="s" element={<Suggestion />} />
                    <Route path="about" element={<AboutPage />} />
                </Route>
            </Routes>
            {/* </React.StrictMode> */}
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
