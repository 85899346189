import MenuIcon from '@mui/icons-material/Menu';
import { Container, Grid, Stack, SwipeableDrawer } from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import './App.css';

function Header(){

  const location = useLocation();

  function NavItem(props) {
    if (location.pathname === props.to) {
      return (<div className="navItem primary"><p>{props.children}</p></div>)
    } else {
      return (<div className="navItem"><a href={props.to}>{props.children}</a></div>)
    }
  }

  const [drawerOpened, setDrawerOpened] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpened(open);
  };

  const MenuStack = (props) => {
    if (props.isSideMenu) {
      return (
        <Stack direction="column" spacing={4} alignItems="center" justifyContent="end" style={{
          padding: '30px',
          minWidth: '200px'
        }} >
          <NavItem to="/">我們的服務</NavItem>
          {/* <NavItem to="/about_us">關於我們</NavItem> */}
        </Stack>
      )
    }
    return (
      <Stack {...props} direction="row" spacing={4} alignItems="center" justifyContent="end" style={{ height: '100%' }} >
        <NavItem to="/">我們的服務</NavItem>
        {/* <NavItem to="/about_us">關於我們</NavItem> */}
      </Stack>
    )
  }
  
  return (
    <Container>
    <Grid container justifyContent="space-between">
      <Grid item xs={10} sm>
        <a href="/"><img alt="Lecopt - Alternative Legal Service Provider" width={'200px'} src="/logo.svg" /></a>

      </Grid>
      <Grid item xs={2} sm>

        <Box display={{ xs: "none", sm: "flex" }} height="100%" justifyContent="end">
          <MenuStack />
        </Box>

        <Fragment >

          <Box alignItems="center" height="100%" display={{ xs: "flex", sm: "none" }}>
            <MenuIcon onClick={toggleDrawer(true)} />
          </Box>
          <SwipeableDrawer
            anchor='right'
            open={drawerOpened}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <MenuStack isSideMenu={true} />

          </SwipeableDrawer>
        </Fragment>


      </Grid>
    </Grid>
  </Container>
  )
}



function App() {

  return (
    // <div className="App">

    //   <Header/>
      <Outlet />

    // </div>
  );
}

export default App;
