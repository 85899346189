const isString = (text) => {
    return (typeof text === 'string' || text instanceof String)
}

export const conditionChecked = (conditions, compareWith)=>{

    if (!conditions){
        return true;
    }

    if (isString(conditions)){
        conditions = [conditions];
    }

    // console.log(conditions);

    return conditions.some((conditionLine) => {
        return conditionLine.split(";").every((conditionSingle)=>{
            const [k, v] = conditionSingle.split("==");
            // console.log(compareWith[k][0].toString(), v.toString());

            if (compareWith[k]){
                if (compareWith[k][0]){
                    return compareWith[k][0].toString() === v.toString()
                } else {
                    return false
                }
                
            } else {
                return false;
            }

        })
    })
}