import { Box } from '@mui/system';
import { Container, Stack, Button, Grid, Card } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';// Default theme
import '@splidejs/react-splide/css';
import { useState } from 'react';

const Page1 = () => {

    const signin_click = ()=>{
        window.location.href = "https://portal.lecopt.tech";
    }

    return <Box sx={{height: "100%", backgroundImage: "url('/BG1.png')", backgroundPosition: "right center", backgroundSize: "cover"}} flexGrow={1} display={"flex"} alignItems={{"xs": "end", "sm": "center"}} color={{xs:"var(--base-text-color)", sm: "var(--primary-text-color)"}}> 
        <Container width={"100%"}>
            <Box fontSize={{xs: 50*0.65, sm: 50*0.8, md: 50}} fontWeight={500}>ALTERNATIVE</Box>
            <Box marginLeft={{xs: 0, sm: 5}}>
                <Box fontSize={{xs: 50*0.65, sm: 50*0.8, md: 50}} fontWeight={800}>LEGAL SERVICE</Box>
                <Box fontSize={{xs: 18*0.65, sm: 18*0.8, md: 18}} fontWeight={500} letterSpacing={{xs: 10*0.65, sm: 10}}>YOU CAN TRUST</Box>
                {/* <Box height={10}></Box> */}
                <Stack direction={{xs: "column", sm: "row"}} alignItems={"center"} spacing={{xs: 10, sm: 2}} style={{marginTop: "20px"}}>
                    
                    <Box alignSelf="start" onClick={signin_click} display={{xs: "block", sm: "none"}} style={{marginLeft: "0px"}}><Button color={"secondary"} variant='outlined' style={{borderRadius: "100px", padding: "2px 8px 0px 8px"}}>Sign in</Button></Box>
                    <Box alignSelf="start" onClick={signin_click} display={{xs: "none", sm: "block"}} style={{marginLeft: "0px"}}><Button color={"primary"} variant='outlined' style={{borderRadius: "100px", padding: "2px 8px 0px 8px"}}>Sign in</Button></Box>
                    <Stack fontWeight={500} direction={{"xs": "column", "sm": "row"}} alignItems={"center"} spacing={{xs:2, sm: 2}} paddingBottom={"20px"}>
                        <div>正式開展法律程序前</div>
                        <div>做好基本準備</div> 
                        <div>節省更多時間</div>
                    </Stack>
                </Stack>
            </Box>
        </Container>
    </Box>  
}

const Page2 = () => {

    const [activeIndex, setActiveIndex] = useState(0)

    const data = [
        ["Small Claims Tribunal", 123],
        ["Labour Tribunal", 456],
        ["Land Tribunal", 567],
        ["District Court", 678],
    ]

    return (<Box position={"relative"} height={"100%"}>

        <img src="/BG2a.png" style={{zIndex:-1, position: "absolute", top: "0px", left: "0px", width: "400px"}}/>
        <img src="/BG2b.png" style={{zIndex:-1, position: "absolute", bottom: "0px", left: "30%", width: "500px"}}/>

        <Box sx={{height: "100%"}} flexGrow={1} display={"flex"} alignItems={"center"} color={"black"} > 
            <Container width={"100%"}>
                <Grid container columnSpacing={10} rowSpacing={10}>
                    <Grid item xs={12} md={4} textAlign={{xs: "center", md: "left"}}>
                        
                        <div style={{fontWeight:700}}><span style={{color: "#FFA012"}}>Hong Kong</span> Market Potential</div>
                        <div style={{height:"10px"}}></div>
                        <div>Caseload at different court levels</div>
                        <div>(Available market for Lecopt)</div>
                        <Grid container marginTop={5} justifyContent={{xs:"center", md:"left"}}>
                            <Grid item xs={12} sm="auto">
                                <Box color={"var(--primary-text-color)"} fontSize={60}>{data[activeIndex][1]}</Box>
                            </Grid>
                            <Grid item xs={12} sm="auto" display="flex" alignItems={"end"} justifyContent="center" paddingBottom="12px">
                                <Box color={"black"} fontSize={20}>cases</Box>
                            </Grid>
                        </Grid>
                    
                    </Grid>
                    <Grid item xs={12} md={8} textAlign={{xs: "center", md: "left"}} fontSize={ {xs:"30px", sm:"40px", md:"50px"}}>
                        <ul style={{    paddingInlineStart:"0px", listStyleType: "none", fontWeight: "700", color: "#D2D2D2D2"}}>

                            {data.map(([text, number], idx)=>{
                                return <li key={text} onClick={()=>setActiveIndex(idx)} style={{color: activeIndex == idx ? "var(--primary-text-color)" : "inherit", cursor:"pointer"}}>{text}</li>
                            })}
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </Box>  
    </Box>)
}



const Page3 = () => {

    const data = [
        // ["動物權益", "animal.png", "animal"],
        ["樓宇問題", "others.png", "building"],
        ["離婚事宜", "divorce.png", "divorce"],
        ["交通意外", "traffic.png", "traffic"],
        // ["創業事宜", "startup.png", "startup"],
        // ["其他事宜", "others.png", "others"],
    ]

    const HeightContainer = (props) => {
        return <Box display={"flex"} flexGrow={{xs:1, md:1}} width={"100%"} paddingTop={{xs:"10px", md:"150px"}} paddingBottom={{xs:"10px", md:"150px"}}>{props.children}</Box>
    }

    return <Grid container height={"100%"} style={{ backgroundColor: "#F4FFF9"}}>
        <Grid item xs={12} md={6} alignItems="center" display="flex" justifyContent={"start"} flexDirection={"column"} height={{md: "100%", xs:"calc(100% - 150px)"}} paddingRight={{xs:"7.95%", md:"0px"}} paddingLeft={{xs:"7.95%", md:"0px"}}>

            <HeightContainer>
                <Grid container className={"smaller_padding_when_too_short"} height={"100%"} paddingTop={{xs:"100px", md:"0px"}} flexDirection={{xs:"column", md:"column"}}>
                    <Grid  className={"disappear_when_too_short"} item xs={12} md={6} flexBasis={{xs:"0%", md:"100%"}}>
                        <Box display={"flex"} flexDirection={"column"} paddingLeft={"50px"} height={"100%"} alignItems={{xs:"end", md:"start"}} position={"relative"}>
                            <Box fontWeight={100} fontSize={{xs:"150px", md:"250px"}} color={"#0BB964"} position={{xs:"absolute", md:""}} left={0}>1</Box>
                            <Box flexGrow={{xs:0, md:1}}></Box>
                            <Box fontWeight={700} color={"#143928"}>填寫資料</Box>
                            <Box fontWeight={100} fontSize={{xs:"60px"}} style={{opacity: 0}} display={{xs: "block", md:"none"}}>1</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} flexBasis={{xs:"0%", md:"100%"}} flexGrow={{xs:1}}>
                        <Box display={"flex"} flexDirection={"column"} height={"100%"} alignItems={{xs:"end", md:"start"}}>
                            <Box fontWeight={400} fontSize={18}color={"#00824D"}>請選擇所需範疇</Box>
                            <Box className={"disappear_when_too_short"} fontWeight={400} fontSize={13}color={"#5F5F5F"}>Lecopt將會提供相關資料</Box>
                            <Box marginTop={"10px"} marginBottom={{xs:"10px", md:"40px"}} width="100%" height="2px" backgroundColor="#0BB964"/>

                            <Box sx={{   
                                paddingInlineStart:"0px", listStyleType: "none", fontWeight: "700", color:"#143928",
                                flexDirection: {
                                    sx:"row",
                                    md:"column"
                                },
                                display: "flex",
                                overflowX: "auto",
                                overflowY: "hidden",
                                maxWidth: "100%",
                                flexGrow: 1
                                }}>
                                {data.map(([text, image, service], idx)=>{
                                return <li style={{
                                    padding:"10px"
                                    }} >
                                    <a href={"/q?category=" + service}>
                                        <Box key={text} padding={{xs:"0px", md:"10px"}} position={"relative"} height={{xs: "calc(100% - 20px)", md: "30px"}} boxShadow={{xs:"0px 2px 3px rgba(0, 0, 0, 0.25)", md:"none"}} backgroundColor={{xs:"#EBFFEF", md:"rgba(0, 0, 0, 0.05)"}} sx={{
                                            
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "4px",
                                            cursor: "pointer",
                                            width: "200px",
                                            '&:hover': {
                                                backgroundColor: "rgba(255, 160, 18, 0.5)"
                                            }
                                        }}>
                                            <img style={{marginRight: "10px"}}src={`/service_icon/${image}`}/>{text}
                                            <Box sx={{ position: 'absolute', right:"0px", top:"0px"}} display={{xs:"block", md:"none"}}>
                                                <img src={`/service_icon/ne_arrow.png`}/>
                                            </Box>
                                            
                                        </Box>
                                    </a>
                                </li>
                            })}
                                
                            </Box>

                            <Box flexGrow={1} display={{xs: "none", md:"block"}}></Box>
                            <Box className={"disappear_when_too_short"} fontSize={13}color={"#5F5F5F"} fontWeight={400} marginTop={"5px"}>*完成此步驟只需5分鐘</Box>
                        </Box>
                    </Grid>
                
                </Grid>
            </HeightContainer>
        </Grid>
        <Grid item xs={12} md={2} height={{xs: "50px", md: "100%"}}>
            <Box height="100%" width="100%" sx={{
                display: "flex",
                backgroundImage: {
                    xs:"linear-gradient(270deg, rgba(141, 219, 150, 0) 0%, rgba(141, 219, 152, 0.6) 100%)",
                    md:"linear-gradient(180deg, rgba(141, 219, 150, 0) 0%, rgba(141, 219, 152, 0.6) 100%)",
                }
            }} >
                <HeightContainer>

                    <Box display={"flex"} alignItems={{xs: "center", md: "start"}} flexDirection={{xs: "row", md: "column"}} paddingLeft={{xs: "10px", lg:"50px"}} height={"100%"} width={"100%"} paddingRight={{xs:"30px", md: "10px"}}>
                        <Box fontWeight={300} fontSize={{xs: 24, md: 160}} color={{xs: "black", md: "#0BB964"}}>2</Box>
                        <Box flexGrow={1}></Box>
                        {/* <Box > */}
                            <Stack direction={{xs: "row", md: "column"}} fontWeight={700} color={"#143928"}>
                                <div>分析並提供</div>
                                <div>初步法律資訊</div>
                            </Stack>
                        {/* </Box> */}
                    </Box>
                    
                </HeightContainer>
            </Box>
        </Grid>
        <Grid item xs={12} md={2} height={{xs: "50px", md: "100%"}}>
            <Box height="100%" width="100%" sx={{
                display: "flex",
                backgroundImage: {
                    xs:"linear-gradient(270deg, rgba(28, 165, 116, 0) 0%, rgba(28, 165, 116, 0.8) 100%)",
                    md:"linear-gradient(180deg, rgba(28, 165, 116, 0) 0%, rgba(28, 165, 116, 0.8) 100%)",
                }
            }} >
                <HeightContainer>

                    <Box display={"flex"} alignItems={{xs: "center", md: "start"}} flexDirection={{xs: "row", md: "column"}} paddingLeft={{xs: "10px", lg:"50px"}} height={"100%"} width={"100%"} paddingRight={{xs:"30px", md: "10px"}}>
                        <Box fontWeight={300} fontSize={{xs: 24, md: 160}} color={{xs: "black", md: "#0BB964"}}>3</Box>
                        <Box flexGrow={1}></Box>
                        <Box fontWeight={700} color={"#143928"}>
                            <Stack direction={{xs: "row", md: "column"}}>
                                <div>準備相關基本文件</div>
                                <div>及安排專業服務</div>
                            </Stack>
                        </Box>
                    </Box>

                </HeightContainer>
            </Box>
        </Grid>
        <Grid item xs={12} md={2} height={{xs: "50px", md: "100%"}}>
            <Box height="100%" width="100%" sx={{
                display: "flex",
                backgroundImage: {
                    xs:"linear-gradient(270deg, rgba(0, 130, 77, 0) 0%, #00824D 100%)",
                    md:"linear-gradient(180deg, rgba(0, 130, 77, 0) 0%, #00824D 100%)",
                }
            }} >

                <HeightContainer>

                    <Box display={"flex"} alignItems={{xs: "center", md: "start"}} flexDirection={{xs: "row", md: "column"}} paddingLeft={{xs: "10px", lg:"50px"}} height={"100%"} width={"100%"} paddingRight={{xs:"30px", md: "10px"}}>
                        <Box fontWeight={300} fontSize={{xs: 24, md: 160}} color={{xs: "black", md: "#0BB964"}}>4</Box>
                        <Box flexGrow={1}></Box>
                        <Box fontWeight={700} color={"#143928"}>
                            <Stack direction={{xs: "row", md: "column"}}>
                                <div>準備妥當</div>
                                <div>與律師會面</div>
                            </Stack>
                        </Box>
                    </Box>

                </HeightContainer>
            </Box>
        </Grid>
    </Grid>

}


export default function (){
    return <>
        <Splide aria-label="Home slides" options={{
                    direction: "ttb",
                    height: "100vh",
                    wheel    : true,
                }}>

            <SplideSlide style={{height: "100vh", width: "100vw"}}>
                <Page1/>    
            </SplideSlide>
            {/* <SplideSlide style={{height: "100vh", width: "100vw"}}>
                <Page2/>
            </SplideSlide> */}
            <SplideSlide style={{height: "100vh", width: "100vw"}}>
                <Page3/>
            </SplideSlide>
        </Splide>
        <Box position={"absolute"} padding={"20px"} top={"0px"}>
            
            <img height="100px" src='/logo.svg'/>

        </Box>
    </>

}