import { useSearchParams } from "react-router-dom";
import landForm from "./definitions/landForm.json"
import divorceForm from "./definitions/divorceForm.json"
import trafficForm from "./definitions/trafficForm.json"
import businessForm from "./definitions/businessForm.json"

import {
    Container, Stack, Box, FormControl, FormLabel,
    FormControlLabel, RadioGroup, Radio, Button, TextField, Alert
} from "@mui/material"
import { useRef, useState, useEffect } from "react";
import { conditionChecked } from "../../util";
// import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react'


function QuestionsInner(props) {

    function Question(props) {
        const _answerStore = props.answerStore;
        const diamondSize = "25px";
        const question = props.value;
        const validating = props.validating;

        var prev_answer = _answerStore[question.question_id];
        if (!prev_answer) {
            prev_answer = [null, ""];
            _answerStore[question.question_id] = prev_answer;
        }

        const [value, setValue] = useState(prev_answer[0]);
        const [tf_value, setTf_value] = useState(prev_answer[0] === -1 ? prev_answer[1] : "");


        const handleChange = (event) => {
            setValue(event.target.value);
            _answerStore[question.question_id] = [event.target.value, question.options[event.target.value]];
        };

        const onTextChange = (event) => {
            setValue(-1);
            setTf_value(event.target.value);
            _answerStore[question.question_id] = [-1, event.target.value];
        }

        return (
            <Box display="flex" marginBottom="30px">
                <Box width={diamondSize} display='flex' flexDirection="column" padding="0px 20px">
                    <Box width={diamondSize} height={diamondSize} position="relative">
                        <Box width="100%" height="100%" border="solid 1px var(--primary-text-color)" sx={{
                            transform: "rotate(45deg)"
                        }}></Box>
                        <Box className="primary" position="absolute"
                            top="0" bottom="0" left="0" right="0"
                            display="flex" alignItems="center" justifyContent="center"
                        > {question.question_id}
                        </Box>
                    </Box>
                    <Box marginTop='12px' width='50%' flexGrow="1" borderRight="solid 1px var(--primary-text-color)"></Box>
                </Box>
                <Box flexGrow="1">
                    <FormControl component="fieldset" error={value == null && validating}>
                        <FormLabel component="legend">{question.question}</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>

                            {question.options.map((option, index) =>
                                <FormControlLabel key={index} value={index} control={<Radio  required={true} />} label={option} />
                            )}

                            {question.has_others ?
                                <FormControlLabel key={question.options.length} value={-1} control={<Radio />} label={
                                    <Box alignItems="center" display="flex"><Box minWidth="max-content" >{question.has_others == true ? "其他" : question.has_others}：</Box>
                                        <TextField
                                            label="點擊填寫"
                                            variant="outlined"
                                            size="small"
                                            value={tf_value}
                                            onChange={onTextChange}
                                        />
                                    </Box>
                                } />
                                : null
                            }

                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        )
    }

    const form = props.form;
    const answerStore = props.answersRef;
    const startSuggestionCb = props.startSuggestion;
    const [page, setPage] = useState(0);
    const [validating, setValidating] = useState(false);
    const sections = form.sections;
    const section = sections[page];

    const filteredQuestions = section.questions.filter((question, index) => {

        return conditionChecked(question.condition, answerStore.current);
    })

    const validate = () => {
        const is_valid = filteredQuestions.every(v => {
            const qid = v['question_id']
            if (!answerStore.current[qid]){
                return false
            } else if (!answerStore.current[qid][0]) {
                return false
            }
            return true
        })

        if (is_valid){
            setValidating(false)
            return true
        } else {
            setValidating(true)
            return false
        }
    }

    const prevPageOnClick = () => {

        // A Stupid loop to check pages backward

        let index = page -1
        let havePageToShow = false;
        for (; index >= 0; index--) {
            const filteredQuestions = sections[index].questions.filter((question, index) => {
                return conditionChecked(question.condition, answerStore.current);
            })
            
            if (filteredQuestions.length > 0){
                havePageToShow = true;
                break
            }
        }

        section.questions.map(question=>question.question_id).map((qid)=>{
            delete answerStore.current[qid];
            console.log(answerStore.current)
        })

        

        setPage(index);
    }
    const nextPageOnClick = () => {

        if (!validate()) return

        if (!havePageToShow){
            startSuggestionCb();
        } else if (page + 1 >= sections.length) {
            startSuggestionCb();
        } else {

            // A Stupid loop to check pages forward

            let index = page + 1
            let havePageToShow = false;
            for (; index < sections.length; index++) {
                const filteredQuestions = sections[index].questions.filter((question, index) => {
                    return conditionChecked(question.condition, answerStore.current);
                })
                
                if (filteredQuestions.length > 0){
                    havePageToShow = true;
                    break
                }
            }

            if (havePageToShow){
                setPage(index);
            } else {
                startSuggestionCb();
            }
            
        }
    }




    // Stupidly repeat these code to check is there pages to show
    let index = page + 1
    let havePageToShow = true;

    if (sections[page].exit_if){
        // console.log("has exit if")

        if (conditionChecked(sections[page].exit_if, answerStore.current)){
            // console.log("Exit if condition meet")
            havePageToShow = false
        }
    }

    if (havePageToShow) {
        for (; index < sections.length; index++) {
            const filteredQuestions = sections[index].questions.filter((question, index) => {
                return conditionChecked(question.condition, answerStore.current);
            })
            
            if (filteredQuestions.length > 0){
                havePageToShow = true;
                break
            }
        }
    }
    

    return (
        <>
            <h2>查詢: {form.title.text}</h2>
            <h3>{section.section_title}</h3>
            <Stack>
                {filteredQuestions.map((question, index) => {
                    return (<Question key={index} answerStore={answerStore.current} value={question} validating={validating}/>)
                })}
            </Stack>
            <Stack direction="row" spacing={2} paddingLeft="30px">
                <Button variant="outlined" onClick={prevPageOnClick} disabled={page === 0}>上一頁</Button>
                <Button variant="contained" onClick={nextPageOnClick}>{
                    !havePageToShow ?
                        "查看結果" : "下一頁"
                }</Button>
            </Stack>
        </>
    )
}

function SuggestionsInner(props) {

    function SuggestionItem(props) {
        const point = props.point
        const answerRef = props.answerRef
        const text = point.text
        const subpoints = point.points
        const condition = point.condition
        const conditionOK = conditionChecked(condition, answerRef.current);
        const style = point.style
        return (conditionOK ?
            <li>
                <p>{text}</p>
                {subpoints ? <ul style={{listStyleType: style}}>{subpoints.map((point, index) => {
                    return (<SuggestionItem point={point} key={index} answerRef={answerRef}/>)
                })}</ul> : null}

            </li> :
            null
        )
    }

    const answerRef = props.answersRef;
    const form = props.form;
    const advices = form.advice

    const ref = React.createRef();

    return (
        <>
            <ul className="suggestionTop" ref={ref}>
                {advices.map((point, index) => {
                    return (<SuggestionItem point={point} key={index} answerRef={answerRef} />)
                })}
            </ul>

            {/* <div>
                <Pdf targetRef={ref} scale={1} filename="code-example.pdf" options={{
                    devicePixelRatio: 3,
                    unit: 'in',
                    format: 'a4',
                    orientation: 'l',
                    dpi: 300
                }}>
                    {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                </Pdf>
            </div> */}

        </>
    )
}

function Summary(props) {
    const form = props.form;
    const answers = props.answersRef.current || {};

    const qid_to_title = Object.fromEntries(form.sections.map((section) => {
        return section.questions.map((question) => {
            return [question.question_id, question.question]
        })
    }).flat())

    console.log("Summary", qid_to_title)

    return (
        <>
            <Box border="solid 1px var(--lc_grey)" padding="20px" display="flex" sx={{
                flexFlow: "wrap"
            }}>
                <Box width="100%" sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    marginBottom: "30px",
                }}><h3>案件概要</h3></Box>


                {Object.entries(answers).map(([k, v], i) =>
                    <Box display="inline-block" minWidth="33%" marginBottom="20px">
                        <Box display="inline-block" marginRight="10px" fontWeight={600}>{qid_to_title[k]}</Box>
                        <Box color="var(--lc_grey)">{v[1]}</Box>
                    </Box>
                )}

            <div style={{width: "100%"}}>
                <a href={`https://wa.me/85298827001?text=${
                    "你好，我想尋求律師協助：%0A%0A" + Object.entries(answers).map(([k, v], i) =>
                        "*" + qid_to_title[k] + "*" + "%20"+ v[1]
                    ).join("%0A")
                }`} target={"_blank"}>
                    <Button color="secondary" variant="contained" style={{float: "right"}}> 立即尋求律師協助！</Button>
                </a>
            </div>
            </Box>

        </>
    )
}

export default function Questionaire() {
    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get("category")

    const [showSuggestions, setShowSuggestions] = useState(false);
    const answers = useRef({})

    const startSuggestion = () => {
        setShowSuggestions(true)
    }

    if (category === 'building') {
        return (
            <Container fixed style={{ maxWidth: "960px", paddingTop: "20px", paddingBottom: "80px" }}>
                {showSuggestions ?
                    <>
                        <Summary form={landForm} answersRef={answers} />
                        <Box width="100%" sx={{
                            textDecoration: "underline",
                            textAlign: "center",
                            marginBottom: "30px",
                        }}><h3>注意事項</h3></Box>
                        <SuggestionsInner form={landForm} answersRef={answers} />
                    </> :
                    <QuestionsInner form={landForm} answersRef={answers} startSuggestion={startSuggestion} />
                }
            </Container>
        );
    } else if (category === 'divorce') {
        return (
            <Container fixed style={{ maxWidth: "960px", paddingTop: "20px", paddingBottom: "80px" }}>
                {showSuggestions ?
                    <>
                        <Summary form={divorceForm} answersRef={answers} />
                        <Box width="100%" sx={{
                            textDecoration: "underline",
                            textAlign: "center",
                            marginBottom: "30px",
                        }}><h3>注意事項</h3></Box>
                        <SuggestionsInner form={divorceForm} answersRef={answers} />
                    </> :
                    <QuestionsInner form={divorceForm} answersRef={answers} startSuggestion={startSuggestion} />
                }
            </Container>
        );
    } else if (category === 'traffic') {
        return (
            <Container fixed style={{ maxWidth: "960px", paddingTop: "20px", paddingBottom: "80px" }}>
                {showSuggestions ?
                    <>
                        <Summary form={trafficForm} answersRef={answers} />

                        <Box width="100%" sx={{
                            textDecoration: "underline",
                            textAlign: "center",
                            marginBottom: "30px",
                        }}><h3>注意事項</h3></Box>
                        <SuggestionsInner form={trafficForm} answersRef={answers} />
                    </> :
                    <QuestionsInner form={trafficForm} answersRef={answers} startSuggestion={startSuggestion} />
                }
            </Container>
        );
    } else if (category === 'business') {
        return (
            <Container fixed style={{ maxWidth: "960px", paddingTop: "20px", paddingBottom: "80px" }}>
                {showSuggestions ?
                    <>
                        <Summary form={businessForm} answersRef={answers} />

                        <Box width="100%" sx={{
                            textDecoration: "underline",
                            textAlign: "center",
                            marginBottom: "30px",
                        }}><h3>注意事項</h3></Box>
                        <SuggestionsInner form={businessForm} answersRef={answers} />
                    </> :
                    <QuestionsInner form={businessForm} answersRef={answers} startSuggestion={startSuggestion} />
                }
            </Container>
        );
    } else {

        const handleClose = () => {
            window.location = "/";
        }

        return (
            <Container>
                <Alert onClose={handleClose} severity="info">
                    The category you chose is under construction :(
                </Alert>
            </Container>
        )
    }
}